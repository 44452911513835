$titleSize: 14px;
$labelBackground: #ffffff;
$chipBackground: #f1f1f1;
$footerTextSize: 12px;
$labelTextSize: 14px;
$labelNeutralAlternative: #edaa0a;
$labelNeutralColor: #616364;
$labelPrimaryColor: #428cc9;
$labelPrimaryHoverColor: #005e91;
$labelSuccessColor: #389c86;
$labelErrorColor: #d9305a;
$labelErrorHoverColor: #cc0000;
$valueTextSize: 22px;
$valueTextWeight: 700;
$contentMargin: 5px;
$infoBoxBackground: #f0f0f082;

// texts
$textSizeMedium: 16px;
$textSizeSmall: 12px;

// links
$linkColor: #0078b9;
$linkHoverColor: #005e91;
