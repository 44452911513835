@import './kpi-icons';
@import './kpi-vars';

.text-bold {
  font-weight: bold;
}

.text-small {
  font-size: $textSizeSmall;
}

.text-neutral {
  color: $labelNeutralColor;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.dialogHeader {
  background-color: #233340;
  color: white;
  font-size: 10px;
  height: 20px;
  display: flex;
  flex-direction: row;
  padding: 20px 5px;

  .dialogTitle {
    padding-left: 10px;
    align-self: center;
    width: 100%;
    font: caption;
  }

  .backButton {
    align-self: center;
    padding-bottom: 17px;
    padding-left: 5px;
    cursor: pointer;
  }

  .closeButton {
    align-self: center;
    cursor: pointer;
    margin-right: 5px;
  }
}

.mat-mdc-dialog-content {
  display: inline-flex;
  padding: 0;
  margin: 0 -24px;
  height: 60vh;
  -webkit-overflow-scrolling: touch;
}

.status-circle-small {
  height: 12px !important;
  width: 12px !important;
  margin-right: 5px !important;
}

.status-circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;

  &-NOT_COMPLIANT,
  &-NOT_ALLOWED {
    background: $labelErrorColor;
  }

  &-UNKNOWN {
    background: $labelNeutralAlternative;
  }

  &-IGNORED,
  &-DEPRECATED {
    background: $labelNeutralColor;
  }

  &-COMPLIANT,
  &-ALLOWED {
    background: $labelSuccessColor;
  }
}

.status-text {
  font-size: $valueTextSize;
  font-weight: $valueTextWeight;
  width: fit-content;
  display: flex;
  align-items: center;

  fusion-icon.info-icon {
    margin-right: 3px;
  }

  &-COMPLIANT,
  &-ALLOWED,
  &-malware-detected-false {
    color: $labelPrimaryColor;
  }

  &-UNKNOWN {
    color: $labelNeutralAlternative;
  }

  &-NOT_COMPLIANT,
  &-NOT_ALLOWED,
  &-malware-detected-true {
    color: $labelErrorColor;
  }

  &-IGNORED,
  &-DEPRECATED {
    color: $labelNeutralColor;
  }

  &-not-available {
    font-size: $labelTextSize;
    font-weight: 400;
    color: $labelNeutralColor;
  }

  &-tooltip-container {
    width: fit-content;
  }
}

.link {
  font-style: italic;
  color: $linkColor;
  cursor: pointer;

  &:hover {
    color: $linkHoverColor;
  }

  .link-text {
    word-wrap: normal;
    word-break: break-word;
  }
}

.open-details {
  color: $linkColor;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: $linkHoverColor;
  }
}

.text-align-right {
  text-align: right;
}

.error-container {
  padding: 10px;
}

.error {
  color: $labelErrorColor;

  &:hover {
    color: $labelErrorHoverColor;
  }

  button {
    color: $labelErrorColor;
  }
}

.pointer-events-activated {
  pointer-events: auto;
}

// necessary for the loading indicator to be centered cause there is no loading template for treelist right now
.custom-loading-indicator-position {
  position: absolute;
  height: 100%;
  width: 100%;
}
