@import '../node_modules/@tools/fusion/scss/colors';
$fusion-color-primary: #0294c7;
$textColor: #000000;

$darkWarningColor: #edaa0a;
$fusion-color-red: rgb(214, 46, 26);
$fusion-color-green: #389c86;

$pipelineExecutionStatusStarted: #ffebba;
$pipelineExecutionStatusSuccess: #c9ecc9;
$pipelineExecutionStatusNotSuccess: #fda0a0;
