/*# # # # # # # # # # # # # # # #
#                               #
# Thanks to Eric A. Meyer.
#	http://meyerweb.com/eric/tools/css/reset/
#	v2.0 | 20110126
#	License: none (public domain) #
#	                              #
#	Changes by Laurens von Assel  #
#                               #
# # # # # # # # # # # # # # # #*/
/*#
  After meeting with fusion team, this file is currently a workaround to avoid the gray background in fusion components
  A fix will be provided to make this file obsolete
 */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  //margin: 0; // Causes problems with sonarQube and Tests tile
  padding: 0;
  border: 0;
  outline: 0;
  //font: inherit;
  vertical-align: inherit;
  //  background: transparent; // That's crap
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}
body {
  /*line-height: 1;*/
  line-height: normal;
  vertical-align: baseline;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*:hover,
*:active {
  outline: none;
}
datalist {
  display: none;
}

// as presented in https://gist.github.com/MoOx/9137295
button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

button:not(:disabled),
a {
  cursor: pointer;
}

button *,
a * {
  cursor: inherit;
}

/* Project-specific resets */
dfn {
  font-style: inherit;
}

/* TODO: To-Do-List:
 *   19: hr
 *   34: outline: 0
 *   38: background: transparend
 *   46-47: line-height: normal
 *   64++: hover, focus, active; datalist

  TODO: Fehlend (yui; als base.scss)
 *   input, textarea {margin:0;padding:0}
 *   caption,th{text-align:left}
 * sup{vertical-align:text-top}sub{vertical-align:text-bottom}

 More styles: https://gist.github.com/DavidWells/18e73022e723037a50d6 & http://html5doctor.com/html-5-reset-stylesheet/

TODO: Consider using https://github.com/shannonmoeller/reset-css & https://www.npmjs.com/package/reset-css
 */
